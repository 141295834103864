<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>

<template>
	<errorContainer :error="erreur">
		<div class="animated fadeIn">
			<b-card class="card-border-blue-light">
				<a ref="scrollTop"></a>
				<div :class="fillClassContainer" class="px-0">
					<b-form @submit="submitForm">
						<!-- Crew member information -->
						<div class="card-collapsible px-0">
							<div
								class="header-card-collapsible d-flex flex-row align-items-center cursor-pointer"
								@click="handleCollapsibleHeader('crew-member-information')"
							>
								<div class="title w-90">
									<b-icon icon="info-circle" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{
										FormMSG(64, 'Crew member information')
									}}</span>
								</div>
								<div class="cursor-pointer d-flex justify-content-end w-10">
									<component
										v-if="!configAccordion['crew-member-information'].isVisible"
										:is="getIconLucide('ChevronDown')"
										color="#06263E"
										:size="22"
									/>
									<component
										v-if="configAccordion['crew-member-information'].isVisible"
										:is="getIconLucide('ChevronUp')"
										color="#06263E"
										:size="22"
									/>
								</div>
							</div>
						</div>
						<b-collapse :visible="configAccordion['crew-member-information'].isVisible">
							<div class="content-card-collapsible">
								<b-row>
									<b-col sm="12" md="12" lg="6" xl="6">
										<b-form-group :label="FormMSG(10, 'Last name')" label-for="name">
											<b-form-input
												v-model="newUser.userName"
												id="name"
												type="text"
												:class="{ 'is-invalid': isSubmitted && $v.newUser.userName.$error }"
											/>
											<div v-if="isSubmitted && !$v.newUser.userName.required" class="invalid-feedback">
												{{ FormMSG(65, 'Please, length should be at least 2') }}
											</div>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="6" xl="6">
										<b-form-group :label="FormMSG(11, 'First name')" label-for="firstName">
											<b-form-input
												v-model="newUser.userFirstName"
												id="firstName"
												type="text"
												:class="{ 'is-invalid': isSubmitted && $v.newUser.userFirstName.$error }"
											/>
											<div v-if="isSubmitted && !$v.newUser.userFirstName.required" class="invalid-feedback">
												{{ FormMSG(65, 'Please, length should be at least 2') }}
											</div>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col sm="12" md="12" lg="6" xl="6">
										<b-form-group :label="FormMSG(12, 'Email')" label-for="email">
											<b-form-input
												v-model="newUser.email"
												id="email"
												type="text"
												:disabled="editData !== null"
												:class="{ 'is-invalid': isSubmitted && $v.newUser.email.$error }"
											/>
											<div v-if="isSubmitted && !$v.newUser.email.emailValid" class="invalid-feedback">
												{{ FormMSG(66, 'Please, enter valid email') }}
											</div>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="6" xl="6">
										<b-form-group :label="FormMSG(13, 'Language')" label-for="language">
											<b-form-select v-model="newUser.language" :options="optionsForLanguages" />
										</b-form-group>
									</b-col>
								</b-row>
							</div>
						</b-collapse>
						<!-- Work general information -->
						<div class="card-collapsible px-0 mt-4 mb-3">
							<div
								class="header-card-collapsible d-flex flex-row align-items-center cursor-pointer"
								@click="handleCollapsibleHeader('work-general-information')"
							>
								<div class="title w-90">
									<b-icon icon="file-earmark-text" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{
										FormMSG(49, 'Work general information')
									}}</span>
								</div>
								<div class="cursor-pointer d-flex justify-content-end w-10">
									<component
										v-if="!configAccordion['work-general-information'].isVisible"
										:is="getIconLucide('ChevronDown')"
										color="#06263E"
										:size="22"
									/>
									<component
										v-if="configAccordion['work-general-information'].isVisible"
										:is="getIconLucide('ChevronUp')"
										color="#06263E"
										:size="22"
									/>
								</div>
							</div>
						</div>
						<b-collapse :visible="configAccordion['work-general-information'].isVisible">
							<div class="content-card-collapsible pt-0">
								<department-selection
									ref="depfun"
									:edit-data="newUser"
									:is-submitted="isSubmitted"
									@department-function-selector:departmentSelected="handleDepartmentChange"
									@department-function-selector:functionSelected="handleFunctionChange"
									@department-function:selector:invalid="isInvalidateFields"
									burger-field
								/>
								<b-row v-if="!isFilmSingle && !isFilmSingleFree">
									<b-col sm="12" md="12" lg="3" xl="3">
										<b-form-group v-uni-for="'startTime'" :label="FormMSG(100, 'Start date & time')">
											<v-date-picker
												v-model="newUser.startTime"
												mode="dateTime"
												:locale="navigatorLang"
												:is24hr="!country12HList.includes(navigatorLang)"
												timezone="UTC"
												:masks="datetimeMask"
											>
												<template #default="{ togglePopover, inputValue, inputEvents }">
													<b-input-group v-on="inputEvents" class="w-100" @focus="isEditable() ? togglePopover : void 0">
														<b-form-input
															:value="inputValue"
															class="form-control"
															style="border-radius: 10px 0 0 10px"
															:placeholder="
																country12HList.includes(navigatorLang)
																	? FormMSG(24575, 'MM/DD/YYYY hh:mm AM')
																	: FormMSG(24569, 'DD/MM/YYYY HH:mm')
															"
															:class="{
																'is-invalid': $v.newUser.startTime.$error
															}"
															v-mask="datetimeVmask"
														/>
														<b-input-group-append style="cursor: pointer">
															<b-input-group-text class="input-group-text bgc-grey-light">
																<component :is="getLucideIcon('Calendar')" :size="20" :stroke-width="2" />
															</b-input-group-text>
														</b-input-group-append>
													</b-input-group>
													<div v-if="$v.newUser.startTime.$error" class="invalid-feedback">
														{{ FormMSG(24570, 'Start date contract, a valid date is required') }}
													</div>
												</template>
											</v-date-picker>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="3" xl="3">
										<b-form-group v-uni-for="'endDate'" :label="FormMSG(51, 'End date (optional)')">
											<v-date-picker
												v-model="newUser.endDate"
												:available-dates="{
													start: moment(newUser.startTime).format('YYYY-MM-DD'),
													end: null
												}"
												:locale="navigatorLang"
												:masks="country12HList.includes(navigatorLang) ? endDateEnMask : endDateFrMask"
												timezone="UTC"
											>
												<template #default="{ togglePopover, inputValue, inputEvents }">
													<b-input-group v-on="inputEvents" @focus="isEditable() ? togglePopover : void 0">
														<b-form-input
															:value="inputValue"
															class="form-control"
															style="border-radius: 10px 0 0 10px"
															:placeholder="
																country12HList.includes(navigatorLang)
																	? FormMSG(24577, 'MM/DD/YYYY')
																	: FormMSG(24571, 'DD/MM/YYYY')
															"
															:class="{
																'is-invalid': endDateValid
															}"
															v-mask="'##/##/####'"
														/>
														<b-input-group-append style="cursor: pointer">
															<b-input-group-text class="input-group-text bgc-grey-light">
																<component :is="getLucideIcon('Calendar')" :size="20" :stroke-width="2" />
															</b-input-group-text>
														</b-input-group-append>
													</b-input-group>
													<div v-if="endDateValid" class="invalid-feedback">
														{{ FormMSG(24572, 'End date contract, a valid date is required') }}
													</div>
													<div v-if="enableEndDateError" class="invalid-feedback">
														{{ FormMSG(4, 'End date should be greater than start date') }}
													</div>
												</template>
											</v-date-picker>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="3" xl="3">
										<b-form-group v-uni-for="'daysforeseens'" :label="FormMSG(52, 'Number of workign days foreseen')">
											<b-form-input v-model="newUser.daysForeseen" v-uni-id="'daysforeseens'" type="number" />
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="3" xl="3">
										<b-form-group v-uni-for="'employeeType'" :label="FormMSG(53, 'Employee type')">
											<b-form-select v-model="newUser.employeeStatus" :options="employeeStatusOptions" />
										</b-form-group>
									</b-col>
								</b-row>
								<b-row v-if="!isFilmSingle && !isFilmSingleFree">
									<!-- isFilmSingleUserFree -->
									<b-col v-if="[0, 1, 3, 4].includes(analyticPeriodType)" sm="12" md="12" lg="3" xl="3">
										<b-form-group v-uni-for="'costCenterPrepsType'" :label="labelCostCenterPreps">
											<treeselect
												v-model="newUser.costCenterPrepsType"
												:multiple="false"
												:clearable="false"
												:options="costCenterPrepsOptions"
												:disable-branch-nodes="true"
												:class="{ 'is-invalid': isSubmitted && $v.newUser.costCenterPrepsType.$error }"
											>
												<div slot="option-label" slot-scope="{ node }">
													<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
												</div>
											</treeselect>
											<div v-if="isSubmitted && $v.newUser.costCenterPrepsType.$error" class="invalid-feedback">
												{{ FormMSG(70, msgPrep) }}
											</div>
										</b-form-group>
									</b-col>
									<b-col v-if="[1, 2, 4].includes(analyticPeriodType)" sm="12" md="12" lg="3" xl="3">
										<b-form-group v-uni-for="'costCenterShootingType'" :label="FormMSG(55, 'Cost center shooting')">
											<treeselect
												v-model="newUser.costCenterShootingType"
												:multiple="false"
												:clearable="false"
												:options="costCenterShootingOptions"
												:disable-branch-nodes="true"
												:class="{ 'is-invalid': isSubmitted && $v.newUser.costCenterShootingType.$error }"
											>
												<div slot="option-label" slot-scope="{ node }">
													<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
												</div>
											</treeselect>
											<div v-if="isSubmitted && $v.newUser.costCenterShootingType.$error" class="invalid-feedback">
												{{ FormMSG(71, msgShooting) }}
											</div>
										</b-form-group>
									</b-col>
									<b-col v-if="[2, 3, 4].includes(analyticPeriodType)" sm="12" md="12" lg="3" xl="3">
										<b-form-group v-uni-for="'costCenterWrapType'" :label="FormMSG(56, 'Cost center wrap')">
											<treeselect
												v-model="newUser.costCenterWrapType"
												:multiple="false"
												:clearable="false"
												:options="costCenterWrapOptions"
												:disable-branch-nodes="true"
												:class="{ 'is-invalid': isSubmitted && $v.newUser.costCenterWrapType.$error }"
											>
												<div slot="option-label" slot-scope="{ node }">
													<div class="treeselect-label" :title="node.label">{{ node.label }}</div>
												</div>
											</treeselect>
											<div v-if="isSubmitted && $v.newUser.costCenterWrapType.$error" class="invalid-feedback">
												{{ FormMSG(72, msgWrap) }}
											</div>
										</b-form-group>
									</b-col>
									<b-col sm="12" md="12" lg="3" xl="3">
										<b-form-group :label="FormMSG(57, 'Budget cost factor')" v-uni-for="'bcfRate'">
											<b-form-input
												v-model="newUser.companyCostFactor"
												v-uni-id="'bcfRate'"
												type="number"
												step="0.00000000001"
												min="1"
												placeholder="1.0"
											></b-form-input>
											<div class="info-message">
												<div><info :size="16" /></div>
												<div class="label">{{ FormMSG(58, 'budget cost = salary*factor') }}</div>
											</div>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col sm="12">
										<fieldset class="card-inside">
											<legend class="card-inside">{{ FormMSG(59, 'Document package') }}</legend>
											<b-row class="inline-flex align-items-center">
												<b-col sm="12" md="12" lg="6" xl="6">
													<b-form-group v-uni-for="'documentPackageId'" :label="FormMSG(60, 'Assign document package')">
														<v-select
															:options="documentPackageOptions"
															id="documentPackageId"
															v-model="newUser.documentPackageId"
															label="name"
															:reduce="(option) => option.id"
															:clearable="false"
														/>
													</b-form-group>
												</b-col>
												<b-col sm="12" md="12" lg="6" xl="6" v-if="newUser.documentPackageId > 0">
													<b-row>
														<b-col sm="12">
															<!--  class="inline-flex align-items-center" -->
															<div class="btn-rounded" @click="handleViewDocumentPackage">
																<span class="icon">
																	<eye color="#5B6164D6" :size="16" />
																</span>
																<span class="label">
																	{{ FormMSG(61, 'View document package') }}
																</span>
															</div>
														</b-col>
													</b-row>
													<b-row class="pt-3" v-if="!isFilmSingle && !isFilmSingleFree">
														<b-col sm="12">
															<b-form-checkbox size="lg" class="pj-cb pb-1" v-model="newUser.isAutomaticSentContract">{{
																FormMSG(62, 'Send automatically once invitation is accepted')
															}}</b-form-checkbox>
														</b-col>
													</b-row>
													<b-row class="pt-3" v-if="isFilmSingle || isFilmSingleFree">
														<b-col sm="12">
															<div class="btn-rounded" @click="handleResendDocumentPackage">
																<span class="icon">
																	<corner-up-left color="#5B6164D6" :size="16" />
																</span>
																<span class="label">
																	{{ FormMSG(63, 'Resend document package') }}
																</span>
															</div>
														</b-col>
													</b-row>
												</b-col>
											</b-row>
										</fieldset>
									</b-col>
								</b-row>
							</div>
						</b-collapse>
						<memo-deal
							ref="memo"
							:edit-data="contractTemplate"
							:displayCopyPaste="true"
							:start-date-update="newUser.startTime.toString()"
							:end-date-update="newUser.endDate.toString()"
							:days-foreseen-update="newUser.daysForeseen"
							:employee-status-update="newUser.employeeStatus"
							:cost-center-preps-type-update="newUser.costCenterPrepsType"
							:cost-center-shooting-type-update="newUser.costCenterShootingType"
							:cost-center-wrap-type-update="newUser.costCenterWrapType"
							:company-cost-factor-update="newUser.companyCostFactor"
							:document-package-id-update="newUser.documentPackageId"
							:is-automatic-sent-contract-update="newUser.isAutomaticSentContract"
							:is-submitted="isSubmitted"
							:fill-class-container="''"
							:disable-save-deal-memo-template="disableSaveTemplate || loadingAddProject"
							:show-pay-code="showPayCode"
							@change="handleMemoDealChange"
							@memo-deal:fields:invalid="isInvalidateFields"
							@memo-deal:fields:clear-error="clearFieldsError"
							@memo-deal:save-template="saveTemplate"
							@memo-deal:department-function:required="handleCcn2642"
						/>
						<!-- Authorization -->
						<div class="card-collapsible px-0 mb-4">
							<div
								class="header-card-collapsible d-flex flex-row align-items-center cursor-pointer"
								@click="handleCollapsibleHeader('authorization')"
							>
								<div class="title w-90">
									<b-icon icon="key" style="color: rgba(6, 38, 62, 0.84)" /><span class="ml-3">{{ FormMSG(16, 'Authorization') }}</span>
								</div>
								<div class="cursor-pointer d-flex justify-content-end w-10">
									<component
										v-if="!configAccordion['authorization'].isVisible"
										:is="getIconLucide('ChevronDown')"
										color="#06263E"
										:size="22"
									/>
									<component v-if="configAccordion['authorization'].isVisible" :is="getIconLucide('ChevronUp')" color="#06263E" :size="22" />
								</div>
							</div>
						</div>
						<b-collapse :visible="configAccordion['authorization'].isVisible">
							<div class="content-card-collapsible">
								<div>
									<div>
										<b-row>
											<b-col sm="12" md="12">
												<fieldset class="card-inside">
													<legend class="card-inside">
														{{ FormMSG(301, 'Application rights') }}
													</legend>
													<b-row style="padding-left: 30px; margin-top: 20px">
														<b-col md="4" class="mb-3">
															<b-form-checkbox
																size="lg"
																v-model="newUser.canManageCrewList"
																:value="true"
																class="pj-cb pb-1"
																:unchecked-value="false"
															>
																{{ FormMSG(2970, 'Manage crew list') }}
															</b-form-checkbox>
														</b-col>
													</b-row>
													<b-row style="padding-left: 30px">
														<b-col v-if="useDoc" md="4" class="mb-3">
															<b-form-checkbox
																size="lg"
																v-model="newUser.canManageDocuments"
																:value="true"
																class="pj-cb pb-1"
																:unchecked-value="false"
															>
																{{ FormMSG(2971, 'Manage documents') }}
															</b-form-checkbox>
														</b-col>
														<b-col md="4" class="mb-3">
															<b-form-checkbox
																v-if="useDoc"
																size="lg"
																v-model="newUser.canManageDocumentPackage"
																:value="true"
																:unchecked-value="false"
																class="pj-cb pb-1"
															>
																{{ FormMSG(2440, 'Manage document package') }}
															</b-form-checkbox>
														</b-col>
														<b-col v-if="useCallSheet" md="4" class="mb-3">
															<b-form-checkbox size="lg" class="pj-cb pb-1" v-model="newUser.canManageCallSheets">
																{{ FormMSG(2450, 'Manage call sheets') }}
															</b-form-checkbox>
														</b-col>
													</b-row>
													<b-row style="padding-left: 30px">
														<b-col v-if="useAcc" md="4" class="mb-3">
															<b-form-checkbox
																size="lg"
																v-model="newUser.canManageAccommodations"
																:value="true"
																class="pj-cb pb-1"
																:unchecked-value="false"
															>
																{{ FormMSG(2973, 'Manage accomodations') }}
															</b-form-checkbox>
														</b-col>
														<b-col v-if="useLoc" md="4" class="mb-3">
															<b-form-checkbox
																size="lg"
																v-model="newUser.canManageLocations"
																:value="true"
																:unchecked-value="false"
																class="pj-cb pb-1"
															>
																{{ FormMSG(2449, 'Manage locations') }}
															</b-form-checkbox>
														</b-col>
														<b-col v-if="useTrans" md="4" class="mb-3">
															<b-form-checkbox
																size="lg"
																v-model="newUser.canManageTransport"
																:value="true"
																:unchecked-value="false"
																class="pj-cb pb-1"
															>
																{{ FormMSG(24409, 'Manage transport') }}
															</b-form-checkbox>
														</b-col>
													</b-row>
													<b-row style="padding-left: 30px">
														<b-col v-if="useImportExport" md="4" class="mb-3">
															<b-form-checkbox size="lg" class="pj-cb pb-1" v-model="newUser.canExportImport">
																{{ FormMSG(2455, 'Can import/export') }}
															</b-form-checkbox>
														</b-col>
														<!-- <b-col v-if="useBudget" md="4" class="mb-3">
															<b-form-checkbox size="lg" class="pj-cb pb-1" v-model="newUser.canViewDepartmentBudget">
																{{ FormMSG(2690, 'View department budget') }}
															</b-form-checkbox>
														</b-col> -->
														<b-col v-if="useBudget" md="4" class="mb-3">
															<b-form-checkbox
																size="lg"
																v-model="newUser.canViewGlobalBudget"
																:value="true"
																:unchecked-value="false"
																class="pj-cb pb-1"
															>
																{{ FormMSG(24430, 'View global budget') }}
															</b-form-checkbox>
														</b-col>
													</b-row>
													<b-row style="padding-left: 30px">
														<b-col v-if="useGreenTable" md="4" class="mb-3">
															<b-form-checkbox size="lg" v-model="newUser.canViewGreenTable" class="pj-cb pb-1">
																{{ FormMSG(24568, 'View Green table') }}
															</b-form-checkbox>
														</b-col>
														<b-col v-if="useReporting" md="4" class="mb-3">
															<b-form-checkbox size="lg" v-model="newUser.canAccessGreenReporting" class="pj-cb pb-1">
																{{ FormMSG(24560, 'View Green reporting screens') }}
															</b-form-checkbox>
														</b-col>
														<b-col v-if="useCarbonRemoval" md="4" class="mb-3">
															<b-form-checkbox
																size="lg"
																v-model="newUser.canManageCarbonRemoval"
																:value="true"
																:unchecked-value="false"
																class="pj-cb pb-1"
															>
																{{ FormMSG(24480, 'Manage carbon removal') }}
															</b-form-checkbox>
														</b-col>
													</b-row>
													<b-row style="padding-left: 30px">
														<b-col v-if="useWaste" md="4" class="mb-3">
															<b-form-checkbox size="lg" class="pj-cb pb-1" v-model="newUser.canViewWasteScreens">
																{{ FormMSG(24455, 'View Waste screens') }}
															</b-form-checkbox>
														</b-col>
														<b-col v-if="useTipOfDay" md="4" class="mb-3">
															<b-form-checkbox size="lg" class="pj-cb pb-1" v-model="newUser.canManageTipOfTheDay">
																{{ FormMSG(24405, 'Manage tip of the day') }}
															</b-form-checkbox>
														</b-col>
													</b-row>
													<b-row style="padding-left: 30px">
														<b-col md="4" class="mb-3">
															<b-form-checkbox size="lg" v-model="newUser.canAccessConfig" class="pj-cb pb-1 mb-3">
																{{ FormMSG(246, 'Can access config') }}
															</b-form-checkbox>
														</b-col>
													</b-row>
													<!-- <b-row class="lh-22" style="margin-top: 20px">
														<b-col xs="12">
															<b-col md="12" class="mb-3">
																<b-form-checkbox
																	class="pj-cb pb-1"
																	size="lg"
																	v-model="newUser.isAdmin"
																	@change="manageValidationAdmin"
																	:value="true"
																	:unchecked-value="false"
																>
																	{{ FormMSG(260, 'View Administration') }}
																</b-form-checkbox>
															</b-col>
															<b-col md="12" class="mb-3" style="padding-left: 30px">
																<b-form-checkbox
																	size="lg"
																	:value="true"
																	class="pj-cb pb-1"
																	v-model="newUser.canAccessMyBudget"
																	:disabled="menuAdministrationDisabled"
																	:unchecked-value="false"
																>
																	{{ FormMSG(269, 'View department budget') }}
																</b-form-checkbox>
															</b-col>
															<b-col md="12" class="mb-3">
																<b-form-checkbox
																	size="lg"
																	v-model="newUser.isProd"
																	class="pj-cb pb-1"
																	@change="manageValidationProd"
																	:value="true"
																	:unchecked-value="false"
																>
																	{{ FormMSG(2610, 'View production') }}
																</b-form-checkbox>
															</b-col>
															<b-row style="padding-left: 30px">
																<b-col md="4" class="mb-3">
																	<b-form-checkbox
																		size="lg"
																		class="pj-cb pb-1"
																		:value="true"
																		v-model="newUser.canAccessUsers"
																		:disabled="menuProductionDisabled"
																		:unchecked-value="false"
																	>
																		{{ FormMSG(297, 'Can access users') }}
																	</b-form-checkbox>
																</b-col>
																<b-col md="4" class="mb-3">
																	<b-form-checkbox
																		size="lg"
																		class="pj-cb pb-1"
																		v-model="newUser.canAccessBudget"
																		:disabled="menuProductionDisabled"
																		:value="true"
																		:unchecked-value="false"
																	>
																		{{ FormMSG(244, 'Can access budget') }}
																	</b-form-checkbox>
																</b-col>
																<b-col md="4" class="mb-3">
																	<b-form-checkbox
																		size="lg"
																		class="pj-cb pb-1"
																		v-model="newUser.canAccessOnboarding"
																		:disabled="menuProductionDisabled"
																	>
																		{{ FormMSG(2445, 'Can access Onboarding') }}
																	</b-form-checkbox>
																</b-col>
															</b-row>
															<b-col md="12" class="mb-3">
																<b-form-checkbox
																	size="lg"
																	class="pj-cb pb-1"
																	v-model="newUser.canAccessConfig"
																	:value="true"
																	:unchecked-value="false"
																>
																	{{ FormMSG(246, 'Can access config') }}
																</b-form-checkbox>
															</b-col>
															<b-col md="12" class="mb-3">
																<b-form-checkbox
																	size="lg"
																	class="pj-cb pb-1"
																	v-model="newUser.canAccessGreenReporting"
																	:value="true"
																	:unchecked-value="false"
																>
																	{{ FormMSG(245, 'Can access green reporting') }}
																</b-form-checkbox>
															</b-col>
														</b-col>
													</b-row> -->
												</fieldset>
												<fieldset v-if="needValidation" class="card-inside">
													<legend class="card-inside">
														{{ FormMSG(302, 'Validation rights') }}
													</legend>
													<div class="lh-22">
														<h6>
															<b>{{ FormMSG(303, 'Validation level for PO, Expenses and Timehseets') }}</b>
														</h6>
														<b-form-select
															v-model="newUser.validationLevel"
															class="pj-cb pb-1"
															:options="optionsForValidationLevel"
															id="valLevel"
															size="md"
														>
														</b-form-select>
													</div>
												</fieldset>
												<fieldset v-if="useDoc" class="card-inside">
													<legend class="card-inside">
														{{ FormMSG(305, 'Onboarding validation role') }}
													</legend>
													<div class="lh-22">
														<b-row>
															<b-col md="4">
																<b-form-checkbox size="lg" class="pj-cb mb-3 pb-1" v-model="newUser.gateKeeper">
																	{{ FormMSG(266, 'Gate Keeper') }}
																</b-form-checkbox>
															</b-col>
															<b-col md="4">
																<b-form-checkbox size="lg" class="pj-cb mb-3 pb-1" v-model="newUser.productionAccountant">
																	{{ FormMSG(261, 'Production Account') }}
																</b-form-checkbox>
															</b-col>
															<b-col md="4">
																<b-form-checkbox size="lg" class="pj-cb mb-3 pb-1" v-model="newUser.upm">
																	{{ FormMSG(262, 'UPM') }}
																</b-form-checkbox>
															</b-col>
															<b-col md="4">
																<b-form-checkbox size="lg" class="pj-cb mb-3 pb-1" v-model="newUser.lineProducer">
																	{{ FormMSG(263, 'Line Producer') }}
																</b-form-checkbox>
															</b-col>
															<b-col md="4">
																<b-form-checkbox size="lg" class="pj-cb mb-3 pb-1" v-model="newUser.studio">
																	{{ FormMSG(265, 'Studio') }}
																</b-form-checkbox>
															</b-col>
															<b-col md="4">
																<b-form-checkbox size="lg" class="pj-cb mb-3 pb-1" v-model="newUser.canValidateI9">
																	{{ FormMSG(270, 'Verify I-9') }}
																</b-form-checkbox>
															</b-col>
														</b-row>
													</div>
												</fieldset>
												<fieldset v-if="usePoAndExp" class="card-inside">
													<legend class="card-inside">
														{{ FormMSG(365, 'Editing authorization') }}
													</legend>
													<div class="lh-22">
														<b-row>
															<b-col v-if="useExp" md="4">
																<b-form-checkbox v-model="newUser.canEditExpense" size="lg" class="pj-cb mb-3 pb-1">
																	{{ FormMSG(250, 'Can edit Expenses') }}
																</b-form-checkbox>
															</b-col>
															<b-col v-if="usePo" md="4">
																<b-form-checkbox v-model="newUser.canEditPo" size="lg" class="pj-cb mb-3 pb-1">
																	{{ FormMSG(255, 'Can edit PO') }}
																</b-form-checkbox>
															</b-col>
															<b-col md="4">
																<b-form-checkbox v-model="newUser.canDeleteAfterValidation" size="lg" class="pj-cb mb-3 pb-1">
																	{{ FormMSG(275, 'Can delete Expenses/PO') }}
																</b-form-checkbox>
															</b-col>
															<b-col v-if="useExpenseInvoiceProject" md="4">
																<b-form-checkbox v-model="newUser.useExpenseInvoice" size="lg" class="pj-cb mb-3 pb-1">
																	{{ FormMSG(4911, 'Can create invoice') }}
																</b-form-checkbox>
															</b-col>
															<b-col v-if="useBudget" md="4">
																<b-form-checkbox v-model="newUser.canMarkEntryAsProcessed" size="lg" class="pj-cb mb-3 pb-1">
																	{{ FormMSG(4910, 'Can Mark Entry As Proccessed') }}
																</b-form-checkbox>
															</b-col>
														</b-row>
													</div>
												</fieldset>
												<fieldset class="card-inside" v-if="useFinance">
													<legend class="card-inside">
														{{ FormMSG(360, 'Accounting/Budget situation') }}
													</legend>
													<div class="lh-22">
														<b-row>
															<b-col md="4">
																<b-form-checkbox v-model="newUser.canLockBudget" size="lg" class="pj-cb mb-3 pb-1">
																	{{ FormMSG(361, 'Can lock/unlock initial budget') }}
																</b-form-checkbox>
															</b-col>
															<b-col md="4">
																<b-form-checkbox
																	v-model="newUser.canModifyEstimatedFinalCost"
																	size="lg"
																	class="pj-cb mb-3 pb-1"
																>
																	{{ FormMSG(362, 'Can modify EFC') }}
																</b-form-checkbox>
															</b-col>
															<b-col md="4">
																<b-form-checkbox v-model="newUser.canEditAndAddCode" size="lg" class="pj-cb mb-3 pb-1">
																	{{ FormMSG(363, 'Can edit/add codes') }}
																</b-form-checkbox>
															</b-col>
														</b-row>
													</div>
												</fieldset>
												<fieldset v-if="showEntryForCrew" class="card-inside">
													<legend class="card-inside">
														{{ FormMSG(356, 'Entry for someone else') }}
													</legend>
													<div class="lh-22">
														<b-row>
															<b-col v-if="showEntryForCrewTimesheet" md="4">
																<b-form-checkbox
																	@change="handleUserEntryLevelChange"
																	v-model="userEntryLevelOnForm.timeSheets"
																	size="lg"
																	class="pj-cb mb-3 pb-1"
																>
																	{{ FormMSG(251, 'Timesheets') }}
																</b-form-checkbox>
															</b-col>
															<b-col v-if="showEntryForCrewExpenses" md="4">
																<b-form-checkbox
																	@change="handleUserEntryLevelChange"
																	v-model="userEntryLevelOnForm.expenses"
																	size="lg"
																	class="pj-cb mb-3 pb-1"
																>
																	{{ FormMSG(252, 'Expenses') }}
																</b-form-checkbox>
															</b-col>
															<b-col v-if="showEntryForCrewPo" md="4">
																<b-form-checkbox
																	@change="handleUserEntryLevelChange"
																	v-model="userEntryLevelOnForm.po"
																	size="lg"
																	class="pj-cb mb-3 pb-1"
																>
																	{{ FormMSG(253, 'PO') }}
																</b-form-checkbox>
															</b-col>
														</b-row>
														<h6
															v-show="userEntryLevelOnForm.po || userEntryLevelOnForm.expenses || userEntryLevelOnForm.timeSheets"
														>
															<b>
																{{ FormMSG(357, 'Department selection') }}
															</b>
														</h6>
														<v-select
															v-show="userEntryLevelOnForm.po || userEntryLevelOnForm.expenses || userEntryLevelOnForm.timeSheets"
															class="style-chooser"
															@input="checkDepartment"
															multiple
															placeholder="Select a department"
															label="text"
															v-model="selectedDepartment"
															:options="allDepartements"
														>
															<template v-slot:option="option">
																<b-form-checkbox v-model="option.selected" disabled size="lg" class="pj-cb mb-3 pb-1">
																	{{ option.text }}
																</b-form-checkbox>
															</template>
														</v-select>
													</div>
												</fieldset>
											</b-col>
										</b-row>
									</div>
								</div>
							</div>
						</b-collapse>
						<!-- Authorization -->

						<!-- Team session -->
						<div v-if="isDeterProject === true" class="card-collapsible px-0 mt-3">
							<div class="cursor-pointer header-card-collapsible d-flex flex-row align-items-center" @click="handleCollapsibleHeader('deter')">
								<div class="title w-90">
									<component :is="getLucideIcon('UserCog')" :size="20" /><span class="ml-3">{{ FormMSG(589, 'Team session') }}</span>
								</div>
								<div class="d-flex justify-content-end w-10">
									<component v-if="!configAccordion['deter'].isVisible" :is="getIconLucide('ChevronDown')" color="#06263E" :size="22" />
									<component v-if="configAccordion['deter'].isVisible" :is="getIconLucide('ChevronUp')" color="#06263E" :size="22" />
								</div>
							</div>
							<b-collapse :visible="configAccordion['deter'].isVisible">
								<div class="content-card-collapsible">
									<b-table-simple id="deterSessionTeamTable">
										<b-thead head-variant="dark">
											<b-tr>
												<b-th></b-th>
												<b-th>{{ FormMSG(457, 'Session 1') }}</b-th>
												<b-th>{{ FormMSG(458, 'Session 2') }}</b-th>
												<b-th>{{ FormMSG(459, 'Session 3') }}</b-th>
												<b-th>{{ FormMSG(460, 'Session 4') }}</b-th>
												<b-th>{{ FormMSG(461, 'Session 5') }}</b-th>
												<b-th>{{ FormMSG(462, 'Session 6') }}</b-th>
												<b-th>{{ FormMSG(463, 'Session 7') }}</b-th>
												<b-th>{{ FormMSG(464, 'Session 8') }}</b-th>
											</b-tr>
										</b-thead>
										<b-tbody>
											<b-tr>
												<b-th>{{ FormMSG(465, 'Team A') }}</b-th>
												<b-td>
													<b-form-checkbox v-model="newUser.session1A" size="lg" :value="true" :unchecked-value="false" />
												</b-td>
												<b-td>
													<b-form-checkbox v-model="newUser.session2A" size="lg" :value="true" :unchecked-value="false" />
												</b-td>
												<b-td>
													<b-form-checkbox v-model="newUser.session3A" size="lg" :value="true" :unchecked-value="false" />
												</b-td>
												<b-td>
													<b-form-checkbox v-model="newUser.session4A" size="lg" :value="true" :unchecked-value="false" />
												</b-td>
												<b-td>
													<b-form-checkbox v-model="newUser.session5A" size="lg" :value="true" :unchecked-value="false" />
												</b-td>
												<b-td>
													<b-form-checkbox v-model="newUser.session6A" size="lg" :value="true" :unchecked-value="false" />
												</b-td>
												<b-td>
													<b-form-checkbox v-model="newUser.session7A" size="lg" :value="true" :unchecked-value="false" />
												</b-td>
												<b-td>
													<b-form-checkbox v-model="newUser.session8A" size="lg" :value="true" :unchecked-value="false" />
												</b-td>
											</b-tr>
											<b-tr>
												<b-th>{{ FormMSG(466, 'Team B') }}</b-th>
												<b-td>
													<b-form-checkbox v-model="newUser.session1B" size="lg" :value="true" :unchecked-value="false" />
												</b-td>
												<b-td>
													<b-form-checkbox v-model="newUser.session2B" size="lg" :value="true" :unchecked-value="false" />
												</b-td>
												<b-td>
													<b-form-checkbox v-model="newUser.session3B" size="lg" :value="true" :unchecked-value="false" />
												</b-td>
												<b-td>
													<b-form-checkbox v-model="newUser.session4B" size="lg" :value="true" :unchecked-value="false" />
												</b-td>
												<b-td>
													<b-form-checkbox v-model="newUser.session5B" size="lg" :value="true" :unchecked-value="false" />
												</b-td>
												<b-td>
													<b-form-checkbox v-model="newUser.session6B" size="lg" :value="true" :unchecked-value="false" />
												</b-td>
												<b-td>
													<b-form-checkbox v-model="newUser.session7B" size="lg" :value="true" :unchecked-value="false" />
												</b-td>
												<b-td>
													<b-form-checkbox v-model="newUser.session8B" size="lg" :value="true" :unchecked-value="false" />
												</b-td>
											</b-tr>
										</b-tbody>
									</b-table-simple>
								</div>
							</b-collapse>
						</div>
						<!-- Team session -->

						<b-row class="d-flex justify-content-center mt-2" :style="`display: ${displayAddBtn ? 'block' : 'none'} !important;`">
							<!-- <b-col cols="2">
                            </b-col> -->
							<b-col sm="12" md="12" lg="4" xl="4">
								<b-button ref="submit" type="submit" size="lg" variant="primary" block>
									<!-- :disabled="disableSave || watingAddProject" -->
									<div class="d-flex justify-content-center align-items-center">
										<!-- <b-spinner v-show="watingAddProject" small /> -->
										<div v-if="editData === null" class="pl-2">{{ this.FormMSG(30, 'Invite crew member') }}</div>
										<div v-if="editData !== null" class="pl-2">{{ this.FormMSG(355, 'Update invitation') }}</div>
									</div>
								</b-button>
							</b-col>
							<b-col cols="4">
								<b-button
									@click="saveMemoDealAsTemplate($event)"
									:disabled="this.disableSaveTemplate || loadingAddProject"
									size="lg"
									variant="primary"
									block
								>
									{{ this.saveMemoDealAsTemplateTitle() }}
								</b-button>
							</b-col>
						</b-row>
					</b-form>
				</div>
			</b-card>
		</div>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';
// import { objReducer } from "@/shared/utils";
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import moment from 'moment';
import { ChevronDown, ChevronUp, Info, Eye, CornerUpLeft } from 'lucide-vue';
import * as icons from 'lucide-vue';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { createUniqIdsMixin } from 'vue-uniq-ids';
const uniqIdsMixin = createUniqIdsMixin();
import { validationMixin } from 'vuelidate';
import { required, minLength, decimal } from 'vuelidate/lib/validators';
import { isNil, emailValid, removeAttributeTree, greaterThanZero, generateSecureId, roundOffNumber, dateFrValid, dateTimeFrValid } from '@/shared/utils';
import { getContractTemplate, addUpdContractTemplate } from '@/cruds/contract.crud';
import { getDocumentList, getDocument } from '@/modules/document-package/cruds/document.crud';
import { omit, noTilde } from '~utils';
import { getBudgetHierarchicalCategoryJson } from '@/cruds/budget.crud';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { getFileExtension, getFileSrc } from '@/shared/helpers';
import { getDepartments } from '../../cruds/department.crud';
import { addEncodingValidation, addUserRequest } from '../../cruds/users.crud';
import _ from 'lodash';
import DeterMixin from '@/mixins/deter.mixin';

import DatePicker from 'v-calendar/lib/components/date-picker.umd';

// Variable doit être dans la query : https://github.com/graphql/graphiql/issues/156
// const mutation_addUser = gql`
// 	mutation ($email: String!) {
// 		addUser(email: $email)
// 	}
// `;

const payCodeKeys = [
	'baseSalary1PayCode',
	'baseSalary2PayCode',
	'baseSalary3PayCode',
	'baseSalary4PayCode',
	'dayOvt1PayCode',
	'dayOvt2PayCode',
	'dayOvt3PayCode',
	'weekOvt1PayCode',
	'weekOvt2PayCode',
	'weekOvt3PayCode',
	'nightTimePayCode',
	'restPayCode',
	'transportPayCode',
	'sundayPayCode',
	'seventhDayPayCode',
	'sixthDayPayCode',
	'beforeTimePayCode',
	'travelAllowancePayCode',
	'carAllowancePayCode',
	'phoneAllowancePayCode',
	'computerAllowancePayCode',
	'boxKitAllowancePayCode',
	'productionFeeAllowancePayCode',
	'lunchPerDiemPayCode',
	'dinerPerDiemPayCode',
	'hotelPerDiemPayCode',
	'abroadPerDiemPayCode'
];

export default {
	name: 'UserForm',
	mixins: [languageMessages, globalMixin, uniqIdsMixin, isSingleProjectMixin, validationMixin, DeterMixin],
	props: {
		projectId: {
			type: Number,
			required: false,
			default: 0
		},
		fillClassContainer: {
			type: String,
			required: false,
			default: 'container-layout' // default empty, or 'container-layout'
		},
		editData: {
			type: Object,
			default: null
		},
		userEntryLevel: {
			type: Object,
			default: null
		},
		displayAddBtn: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	components: {
		departmentSelection: () => ({
			component: import('@/components/DepartmentFunctionSelection')
		}),
		memoDeal: () => ({
			component: import('@/components/MemoDeal')
		}),
		'v-date-picker': DatePicker,
		ChevronDown,
		ChevronUp,
		Info,
		Eye,
		CornerUpLeft,
		Treeselect
	},
	data() {
		return {
			endDateValid: false,
			enableEndDateError: false,
			navigatorLang: this.navigatorLanguage(),
			roundOffNumber,
			erreur: {},
			showMemoDeal: 0,
			// editData: {
			//     type: Object,
			//     required: false,
			//     default: null
			// },
			contractTemplate: {
				defaultDayType: 0,
				dailyRate: 0,
				travelDailyRate: 0,
				hourlyRate: 35,
				overtimeRate: 0,
				nightTimeRate: 0,
				hourBeforeTimeRate: 0,
				kmRate: 0,
				lunchPerDiem: 0,
				hotelPerDiem: 0,
				dailyRightTransfer: 0,
				nightTimeStart: '2019-01-01T00:00:00Z',
				nightTimeEnd: '2019-01-01T00:00:00Z',
				minTimeBetweenDays: '2019-01-01T00:00:00Z',
				lunchMinimum: 0,
				totMinPerDayStr: '00:00',
				totMinPerWeekStr: '00:00',
				sixthDay: 100,
				seventhDay: 100,
				timeManagementType: 0,
				weeklyOvertime: false,
				workSixDays: 0,
				weeklyFiveDaysMinimumSalary: 995,
				weeklySixDaysMinimumSalary: 1141,
				weeklyFiveDaysEffNumHours: 43,
				weeklyFiveDaysEquNumHours: 46,
				weeklySixDaysEffNumHours: 52,
				weeklySixDaysEquNumHours: 56,
				weeklyBonusAmount: 800,
				weeklyFiveDaysRefSalary: 1501,
				weeklySixDaysRefSalary: 1985,
				weeklyOvtOneLimit: 35,
				weeklyOvtOneRate: 125,
				weeklyOvtTwoLimit: 43,
				weeklyOvtTwoRate: 150,
				weeklyOvtThreeLimit: 48,
				weeklyOvtThreeRate: 175,
				weeklyBaseEffNumHours: 39,
				hourlyRateAnnexThree: 23,
				weeklyBonusAmountFiveDays: 950,
				weeklyBonusAmountSixDays: 1200,
				weeklyBaseRefSalary: 1415,
				weeklyBaseMinimumSalary: 970,
				weeklyFiveDaysMinimumSalaryAnThree: 910,
				weeklySixDaysMinimumSalaryAnThree: 1140,
				transportRate: 0,
				transportPaidAfter: 0,
				ovtLimit1: 0,
				ovtLimit2: 0,
				ovtRate1: 100,
				ovtRate2: 100,
				sundayPublicHolidayFactor: 100,
				companyCostFactor: 1,
				minTimeBetweenWeek: 0,
				transportPaidAfterStr: '00:00',
				ovtLimit1Str: '00:00',
				ovtLimit2Str: '00:00',
				minTimeBetweenWeekStr: '00:00',
				totMinPerDayForContDay: 0,
				totMinPerDayForContDayStr: '00:00',
				lunchMinimumForContDay: 0,
				weeklyBaseEffNumHoursStr: '00:00',
				weeklyOvtOneLimitStr: '00:00',
				weeklyOvtTwoLimitStr: '00:00',
				weeklyOvtThreeLimitStr: '00:00',
				lunchMinimumStr: '00:00',
				lunchMinimumForContDayStr: '00:00',
				employeeStatus: 0,
				isAutomaticSentContract: false,
				costCenterPrepsType: 0,
				costCenterShootingType: 0,
				costCenterWrapType: 0,
				documentPackageId: 0,
				useCarAllowance: false,
				carAllowanceRate: 0,
				carAllowance: 0,
				useProductionFeeAllowance: false,
				productionFeeAllowanceRate: 0,
				productionFeeAllowance: 0,
				useDinnerPerDiem: false,
				dinnerPerDiem: 0,
				useComputerAllowance: false,
				computerAllowanceRate: 0,
				computerAllowance: 0,
				minimumHourGuaranteed: 0,
				minimumHourGuaranteedType: 0,
				minimumHourGuaranteedStr: '00:00',
				beforeCallTimeRate: 0,
				ifWorkAboveDailyHours: false,
				usePhoneAllowance: false,
				phoneAllowanceRate: 0,
				phoneAllowance: 0,
				useBoxKitAllowance: false,
				boxKitAllowanceRate: 0,
				boxKitAllowance: 0,
				useAbroadPerDiem: false,
				abroadPerDiem: 0,
				useHotelPerDiem: false,
				useLunchPerDiem: false,
				note: '',
				weeklyFiveDaysEffNumHoursStr: '00:00',
				weeklyFiveDaysEquNumHoursStr: '00:00',
				weeklySixDaysEffNumHoursStr: '00:00',
				weeklySixDaysEquNumHoursStr: '00:00',
				contractReference: '',
				contractType: '',
				dailyOvertime: false,
				ovtLimit: 0,
				ovtLimitContinuousDay: 0,
				ovtLimitContinuousDay1: 0,
				ovtLimitContinuousDay2: 0,
				ovtLimitStr: '00:00',
				ovtLimitContinuousDayStr: '00:00',
				ovtLimitContinuousDay1Str: '00:00',
				ovtLimitContinuousDay2Str: '00:00',
				monthlyRate: 0,
				useTravelDailyRate: false,
				baseSalary1PayCode: null,
				baseSalary2PayCode: null,
				baseSalary3PayCode: null,
				baseSalary4PayCode: null,
				dayOvt1PayCode: null,
				dayOvt2PayCode: null,
				dayOvt3PayCode: null,
				weekOvt1PayCode: null,
				weekOvt2PayCode: null,
				weekOvt3PayCode: null,
				nightTimePayCode: null,
				restPayCode: null,
				transportPayCode: null,
				sundayPayCode: null,
				seventhDayPayCode: null,
				sixthDayPayCode: null,
				beforeTimePayCode: null,
				travelAllowancePayCode: null,
				carAllowancePayCode: null,
				phoneAllowancePayCode: null,
				computerAllowancePayCode: null,
				boxKitAllowancePayCode: null,
				productionFeeAllowancePayCode: null,
				lunchPerDiemPayCode: null,
				dinerPerDiemPayCode: null,
				hotelPerDiemPayCode: null,
				abroadPerDiemPayCode: null,
				collectiveAgreement: '',
				echelon: '',
				analyticCode: '',
				cdiMonthlyReferenceSalary: 0,
				publicHolidayCalendar: null
			},
			newUser: {
				email: '',
				userFirstName: '',
				userName: '',
				language: 0,
				startDate: '',
				startTime: new Date().setUTCHours(8, 0),
				endDate: '',
				department: 0,
				function: 0,
				isAdmin: 0,
				isProd: 0,
				canManageDocuments: false,
				canManageDocumentPackage: false,
				canManageCallSheets: false,
				canManageAccommodations: false,
				canManageLocations: false,
				canExportImport: false,
				canManageCarbonRemoval: false,
				canManageTipOfTheDay: false,
				canViewDepartmentBudget: false,
				canManageCrewList: false,
				canViewGlobalBudget: false,
				canViewConfigurationScreen: false,
				canViewReportingScreens: false,
				canAccessUsers: false,
				canAccessBudget: false,
				canAccessConfig: false,
				canAccessGreenReporting: false,
				canAccessMyBudget: false,
				canAccessOnboarding: false,
				canAccessCrewList: false,
				gateKeeper: false,
				productionAccountant: false,
				upm: false,
				lineProducer: false,
				studio: false,
				canValidateI9: false,
				validationLevel: 0,
				canEditExpense: false,
				useExpenseInvoice: true,
				canMarkEntryAsProcessed: true,
				canEditPo: false,
				canDeleteAfterValidation: false,
				canLockBudget: false,
				canModifyEstimatedFinalCost: false,
				canEditAndAddCode: false,
				showMyEmailInCrew: true,
				showMyPhoneInCrew: true,
				session1A: false,
				session1B: false,
				session2A: false,
				session2B: false,
				session3A: false,
				session3B: false,
				session4A: false,
				session4B: false,
				session5A: false,
				session5B: false,
				session6A: false,
				session6B: false,
				session7A: false,
				session7B: false,
				session8A: false,
				session8B: false
			},
			errors: [],
			selected: [], // Must be an array reference!
			show: true,
			allDepartements: [],
			selectedDepartment: [],
			allDeps: {
				value: -1,
				message: 'All Departments'
			},
			userEntryLevelOnForm: {},
			menuProductionDisabled: true,
			menuAdministrationDisabled: true,
			loadingAddProject: false,
			isSubmitted: false,
			configAccordion: {
				'crew-member-information': {
					isVisible: true
				},
				'work-general-information': {
					isVisible: true
				},
				deter: {
					isVisible: true
				},
				authorization: {
					isVisible: true
				}
			},
			costCenterPrepsOptions: [],
			costCenterShootingOptions: [],
			costCenterWrapOptions: [],
			documentPackageOptions: [
				{
					id: 0,
					name: 'Please, select a document package'
				}
			],
			watingAddProject: false,
			valueFromProps: false,
			msgPrep: 'Please, select a prep',
			msgShooting: 'Please, select a shooting',
			msgWrap: 'Please, select a wrap',
			currentValidCategoryTree: null,
			showPayCode: false,
			moment,
			startDateFrMask: {
				input: 'DD/MM/YYYY HH:mm'
			},
			startDateEnMask: {
				input: 'MM/DD/YYYY hh:mm A',
				inputDateTime: 'MM/DD/YYYY hh:mm A'
			},
			endDateFrMask: {
				input: 'DD/MM/YYYY'
			},
			endDateEnMask: {
				input: 'MM/DD/YYYY'
			}
		};
	},
	async created() {
		this.initDates();
		await this.getDepartment();
		if (!isNil(this.userEntryLevelOnForm.department)) {
			this.checkDepartment(this.userEntryLevelOnForm.department, true);
		}
		await this.initCostCenter(this.newUser.department);
		await this.getDocumentTemplates();
		// console.log({ myProject: store.getCurrentProjectConfig() });
		this.showPayCode = store.getCurrentProjectConfig().payrollInterface === 0 ? false : true;

		this.$v.$touch();
	},
	watch: {
		'newUser.startTime': {
			handler(val) {
				this.newUser.startDate = `${moment(val).format('YYYY-MM-DDTHH:mm:ss')}Z`;
			},
			immediate: true,
			deep: true
		},
		'newUser.endDate': {
			handler(val) {
				this.newUser.endDate = `${moment(val).format('YYYY-MM-DDT23:59:59')}Z`;
				if (this.newUser.endDate !== '') {
					this.endDateValid = false;
				}
			},
			immediate: true,
			deep: true
		},
		'newUser.isAdmin': {
			handler(val) {
				if (!val) {
					this.menuAdministrationDisabled = true;
					this.newUser.canAccessMyBudget = false;
				} else {
					this.menuAdministrationDisabled = false;
					this.newUser.canAccessMyBudget = true;
				}
			},
			immediate: true
		},
		'newUser.isProd': {
			handler(val) {
				if (!val) {
					this.menuProductionDisabled = true;
					if (!this.valueFromProps) {
						this.newUser.canAccessOnboarding = false;
						this.newUser.canAccessUsers = false;
						this.newUser.canAccessBudget = false;
						this.newUser.canAccessCrewList = false;
					}
				} else {
					this.menuProductionDisabled = false;
					if (!this.valueFromProps) {
						this.newUser.canAccessOnboarding = true;
						this.newUser.canAccessUsers = true;
						this.newUser.canAccessBudget = true;
						this.newUser.canAccessCrewList = true;
					}
				}
			},
			immediate: true
		},
		newUser: {
			handler(val) {
				if (new Date(this.newUser.endDate) < new Date(this.newUser.startDate)) {
					this.enableEndDateError = true;
				} else {
					this.enableEndDateError = false;
				}
			}
		},
		userEntryLevel: {
			handler(val) {
				if (isNil(val)) {
					return;
				}
				this.userEntryLevelOnForm = val;
			},
			immediate: true,
			deep: true
		},
		editData: {
			handler(val) {
				if (!_.isNil(val)) {
					this.valueFromProps = true;
					this.contractTemplate = val.contractTemplate;
					this.newUser = omit(['contractTemplate'], val);
					// console.log(this.newUser);
				} else {
					this.valueFromProps = false;
				}
			},
			immediate: true,
			deep: true
		},
		'store.state.myProject.payrollInterface': {
			handler(value) {
				if (!_.isNil(value)) {
					this.showPayCode = value === 0 ? false : true;
				}
			}
		}
	},
	computed: {
		datetimeMask() {
			const mask = this.country12HList.includes(this.navigatorLang) ? this.startDateEnMask : this.startDateFrMask;
			return mask;
		},
		datetimeVmask() {
			const vmask = this.country12HList.includes(this.navigatorLang) ? '##/##/#### ##:## AA' : '##/##/#### ##:##';
			return vmask;
		},
		useExp() {
			return store.getCurrentProjectConfig().useExpense;
		},
		usePo() {
			return store.getCurrentProjectConfig().usePurchaseOrder;
		},
		usePoAndExp() {
			return store.getCurrentProjectConfig().useExpense || store.getCurrentProjectConfig().usePurchaseOrder;
		},
		needValidation() {
			return (
				store.getCurrentProjectConfig().useExpense || store.getCurrentProjectConfig().usePurchaseOrder || store.getCurrentProjectConfig().useTimesheet
			);
		},
		useCarbonTemplate() {
			return store.getCurrentProjectConfig().useCarbonTemplate;
		},
		useGreenTable() {
			return store.getCurrentProjectConfig().useGreenTable;
		},
		useFinance() {
			return store.getCurrentProjectConfig().useFinance;
		},
		useCallSheet() {
			return store.getCurrentProjectConfig().useCallSheet;
		},
		useReporting() {
			return store.getCurrentProjectConfig().useReporting;
		},
		useCarbonRemoval() {
			return store.getCurrentProjectConfig().useCarbonRemoval;
		},
		useExpenseInvoiceProject() {
			return store.getCurrentProjectConfig().useExpenseInvoice;
		},
		useWaste() {
			return store.getCurrentProjectConfig().useWaste;
		},
		useTipOfDay() {
			return store.getCurrentProjectConfig().useTipOfDay;
		},
		useBudget() {
			return store.getCurrentProjectConfig().useBudget;
		},
		useImportExport() {
			return store.getCurrentProjectConfig().useImport || store.getCurrentProjectConfig().useExport;
		},
		useTrans() {
			return store.getCurrentProjectConfig().useTransport;
		},
		useLoc() {
			return store.getCurrentProjectConfig().useLocations;
		},
		useAcc() {
			return store.getCurrentProjectConfig().useAccommodation;
		},
		useDoc() {
			return store.getCurrentProjectConfig().useDocument;
		},
		labelCostCenterPreps() {
			if (this.analyticPeriodType === 0) {
				return this.FormMSG(354, 'Cost center');
			} else {
				return this.FormMSG(54, 'Cost center preps');
			}
		},
		analyticPeriodType() {
			return store.getCurrentProjectConfig().analyticPeriodType;
		},
		showEntryForCrew() {
			return store.getCurrentProjectConfig().useEntryForCrew;
		},
		showEntryForCrewTimesheet() {
			return store.getCurrentProjectConfig().useEntryForCrewTimesheet;
		},
		showEntryForCrewExpenses() {
			return store.getCurrentProjectConfig().useEntryForCrewExpenses;
		},
		showEntryForCrewPo() {
			return store.getCurrentProjectConfig().useEntryForCrewPo;
		},
		isFilm() {
			return store.isFilm();
		},
		optionsForValidationLevel() {
			return this.FormMenu(1113);
		},
		optionsForLanguages() {
			let menus = this.FormMenu(1);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return menus;
		},
		disableSave() {
			if (
				this.newUser.userName.length == 0 ||
				this.newUser.userFirstName.length == 0 ||
				this.newUser.email.length == 0 ||
				this.newUser.department == 0 ||
				this.newUser.function == 0 ||
				this.newUser.startDate.length == 0 ||
				this.newUser.endDate.length == 0
			) {
				return true;
			}
			return false;
		},
		disableSaveTemplate() {
			if (this.newUser.department == 0 || this.newUser.function == 0) {
				return true;
			}
			return false;
		},
		employeeStatusOptions() {
			return this.FormMenu(1331);
		}
	},
	methods: {
		checkDepartment(value, created) {
			this.allDepartements.forEach((item) => {
				item.selected = false;
			});
			value.forEach((item1) => {
				if (item1.value === -1) {
					this.selectedDepartment = [this.allDepartements[0]];
					this.allDepartements.forEach((item) => {
						item.selected = true;
					});
				} else {
					const found = this.allDepartements.find((item2) => item1.value === item2.value);
					if (found) {
						found.selected = true;
						if (created) {
							this.selectedDepartment.push(found);
						}
					}
				}
			});
			this.handleUserEntryLevelChange();
		},
		handleUserEntryLevelChange() {
			let payload = {
				po: this.userEntryLevelOnForm.po,
				timeSheets: this.userEntryLevelOnForm.timeSheets,
				expenses: this.userEntryLevelOnForm.expenses,
				department: []
			};
			this.selectedDepartment.forEach((dep) => {
				payload.department.push(dep.value);
			});
			this.userEntryLevelOnForm = payload;
		},
		mapDepartments(deps) {
			const departments = deps.map((d) => ({
				value: d.value,
				text: d.message,
				selected: false
			}));
			return departments;
		},
		async getDepartment() {
			this.allDepartements = [];
			let Deps = [];
			Deps.push(Object.assign({}, this.allDeps));
			Deps[0].message = this.FormMSG(201, 'All departments');
			await getDepartments(false, false).then((result) => {
				Deps = [...Deps, ...result];
				this.allDepartements = this.mapDepartments(Deps);
			});
		},
		getMemoDealTitle() {
			if (store.isPme()) {
				return this.FormMSG(127, 'Contract details');
			}

			return this.FormMSG(27, 'Memo deal');
		},
		saveMemoDealAsTemplateTitle() {
			if (store.isPme()) {
				return this.FormMSG(131, 'Save Contract as Template');
			}

			return this.FormMSG(31, 'Save Memo Deal as Template');
		},
		initDates() {
			// console.log("in initdates start date",this.newUser.startDate);
			if (this.newUser.startDate.length == 0) {
				var currentDateWithFormat = new Date().toJSON().slice(0, 10);
				this.newUser.startDate = currentDateWithFormat;
				this.newUser.startTime = moment(currentDateWithFormat).local(true).startOf('day').add(8, 'hours').format('YYYY-MM-DDTHH:mm:ss') + 'Z';

				// setting end date 30 days later
				// Convert 'days' to milliseconds
				var millies = 1000 * 60 * 60 * 24 * 30;

				// Get the current date/time
				var todaysDate = new Date();

				// Get 'todaysDate' as Epoch Time, then add 'days' number of mSecs to it
				var futureMillies = todaysDate.getTime() + millies;

				// Use the Epoch time of the targeted future date to create
				//   a new Date object
				this.newUser.endDate = new Date(futureMillies).toJSON().slice(0, 10);
				this.newUser.daysForeseen = 22;
			}

			return true;
		},
		getLastObjectWithNoChildren(id) {
			const targetObject = this.findObjectById(this.costCenterPrepsOptions, id);

			if (targetObject && targetObject.children && targetObject.children.length > 0) {
				return this.getLastObjectWithNoChildren(targetObject.children[0].id);
			}

			if (targetObject) {
				// console.log('Last Object ID:', targetObject.id);
				return targetObject.id;
			}

			console.log('Object not found');
			return null;
		},
		findObjectById(tree, id) {
			for (const node of tree) {
				if (node.id === id) {
					return node;
				}

				if (node.children && node.children.length > 0) {
					const foundObject = this.findObjectById(node.children, id);
					if (foundObject) {
						return foundObject;
					}
				}
			}

			return null;
		},
		async handleFunctionChange(element) {
			if (!this.valueFromProps) {
				this.newUser = {
					...this.newUser,
					...element
				};

				// console.log('in handleFunctionChange this.newuser :', this.newUser);
				// loading template for new department/function
				if (
					isNaN(this.newUser.department) == false &&
					isNaN(this.newUser.function) == false &&
					this.newUser.department != 0 &&
					this.newUser.function != 0
				) {
					this.showMemoDeal = 1;
					// load template
					const departmentNumber = parseInt(this.newUser.department, 10);
					const functionNumber = parseInt(this.newUser.function, 10);
					const catNumber = parseInt(element.category, 10);
					const catNumberId = this.getLastObjectWithNoChildren(catNumber);

					await getContractTemplate(departmentNumber, functionNumber)
						.then(async (record) => {
							if (
								parseInt(record.costCenterPrepsType, 10) === 0 ||
								parseInt(record.costCenterShootingType, 10) === 0 ||
								parseInt(record.costCenterWrapType, 10) === 0
							) {
								// const defaultValueCategoryTree = getDefaultValueCategoryTree(
								// 	this.currentValidCategoryTree,
								// 	functionNumber,
								// 	departmentNumber,
								// 	0
								// );

								if (parseInt(record.costCenterPrepsType, 10) === 0) {
									this.newUser.costCenterPrepsType = catNumberId;
								}

								if (parseInt(record.costCenterShootingType, 10) === 0) {
									this.newUser.costCenterShootingType = catNumberId;
								}

								if (parseInt(record.costCenterWrapType, 10) === 0) {
									this.newUser.costCenterWrapType = catNumberId;
								}
							} else {
								if (parseInt(record.costCenterPrepsType, 10) > 0) {
									this.newUser.costCenterPrepsType = record.costCenterPrepsType;
								}

								if (parseInt(record.costCenterShootingType, 10) > 0) {
									this.newUser.costCenterShootingType = record.costCenterShootingType;
								}

								if (parseInt(record.costCenterWrapType, 10) > 0) {
									this.newUser.costCenterWrapType = record.costCenterWrapType;
								}
							}
							this.newUser.companyCostFactor = record.companyCostFactor;
							record.dailyRate = roundOffNumber(record.dailyRate);
							record.hourlyRate = roundOffNumber(record.hourlyRate);
							record.weeklyFiveDaysMinimumSalary = roundOffNumber(record.weeklyFiveDaysMinimumSalary);

							record = {
								...record,
								costCenterPrepsType: this.newUser.costCenterPrepsType,
								costCenterShootingType: this.newUser.costCenterShootingType,
								costCenterWrapType: this.newUser.costCenterWrapType,
								employeeStatus: this.newUser.employeeStatus,
								companyCostFactor: this.newUser.companyCostFactor,
								isAutomaticSentContract: this.newUser.isAutomaticSentContract,
								documentPackageId: parseInt(this.newUser.documentPackageId, 10)
							};

							record = this.initializedPayCode(record);
							// copy received data to child component
							this.$refs.memo.contract = {
								...this.$refs.memo.contract,
								...record,
								departmentId: departmentNumber,
								functionId: functionNumber
							};
							this.initializedPayCodeByRef('memo', record);
						})
						.catch((error) => {
							this.erreur = error;
							this.errors.push(error);
						});
				} else {
					this.showMemoDeal = 0;
				}
			}
			this.valueFromProps = false;
		},
		async handleDepartmentChange(payload) {
			await this.initCostCenter(payload).then(() => {
				this.newUser.department = payload;
				this.newUser.function = 0;
				this.newUser.costCenterPrepsType = 0;
				this.newUser.costCenterShootingType = 0;
				this.newUser.costCenterWrapType = 0;

				// copy received data to child component
				this.$refs.memo.contract = {
					...this.$refs.memo.contract,
					costCenterPrepsType: this.newUser.costCenterPrepsType,
					costCenterShootingType: this.newUser.costCenterShootingType,
					costCenterWrapType: this.newUser.costCenterWrapType
				};
			});
		},
		manageValidationProd(checked) {},
		manageValidationAdmin(checked) {
			if (checked && this.newUser.validationLevel == 0) {
				//  this.newUser.validationLevel = 1;
			}
		},
		handleMemoDealChange(element) {
			this.contractTemplate = {
				...this.contractTemplate,
				...element,
				startTime: this.newUser.startTime
			};

			//console.log("this.newUser before:", this.newUser);
			this.newUser = {
				...this.newUser,
				...this.contractTemplate,
				function: this.newUser.function,
				department: this.newUser.department,
				startTime: this.newUser.startTime
			};
		},
		async saveTemplate(payload) {
			return await this.saveMemoDealAsTemplate(payload);
		},
		async saveMemoDealAsTemplate(e) {
			e.preventDefault();
			this.isSubmitted = true;

			// console.log("in submitForm this.newuser :", this.newUser);
			const isInvalid = await this.asyncIsinvalid();

			this.$v.$touch();
			if (this.$v.$invalid || isInvalid || this.enableEndDateError) {
				this.createToastForMobile(
					this.FormMSG(14, 'Error'),
					this.FormMSG(15, 'Please verify, some fields are required or format is not valid.'),
					'',
					'danger'
				);

				return;
			}
			// let NewContractTemplate = objReducer(Object.keys(this.contractTemplate), this.newUser);
			let NewContractTemplate = this.contractTemplate;
			NewContractTemplate.department = this.newUser.department;
			NewContractTemplate.function = this.newUser.function;

			// Format start and end date
			NewContractTemplate.startDate = moment(this.newUser.startTime).local(true).startOf('day').format('YYYY-MM-DDTHH:mm:ss') + 'Z';
			NewContractTemplate.startTime = moment(this.newUser.startTime).local(true).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
			NewContractTemplate.endDate = moment(this.newUser.endDate).local(true).endOf('day').format('YYYY-MM-DDTHH:mm:ss') + 'Z';

			// convert elements to the right type as memoDeal returns strings

			NewContractTemplate.nightTimeStart = this.updateTime(this.contractTemplate.nightTimeStart);
			NewContractTemplate.nightTimeEnd = this.updateTime(this.contractTemplate.nightTimeEnd);
			NewContractTemplate.minTimeBetweenDays = this.updateTime(this.contractTemplate.minTimeBetweenDays);
			NewContractTemplate.documentPackageId = parseInt(NewContractTemplate.documentPackageId, 10);
			NewContractTemplate.monthlyRate = parseFloat(NewContractTemplate.monthlyRate);

			await addUpdContractTemplate(NewContractTemplate)
				.then(() => {
					this.createToastForMobile(this.FormMSG(67, 'Success'), this.FormMSG(68, 'Deal Memo saved as template successfully'), '');
				})
				.catch((error) => {
					this.erreur = error;
					this.errors.push(error);
				});
		},
		async submitForm(e) {
			e.preventDefault();
			this.isSubmitted = true;

			// console.log('in submitForm this.newuser :', this.newUser);
			const isInvalid = await this.asyncIsinvalid();

			this.$v.$touch();
			if (this.$v.$invalid || isInvalid || this.newUser.endDate == 'Invalid dateZ' || this.newUser.endDate === '') {
				this.createToastForMobile(
					this.FormMSG(14, 'Error'),
					this.FormMSG(15, 'Please verify, some fields are required or format is not valid.'),
					'',
					'danger'
				);

				this.endDateValid = true;
				this.scrollUp();

				return;
			}

			this.loadingAddProject = true;
			this.errors = [];
			// the following should not happen as the submit button is disabled if fields are not filled

			/** the format of the startDate and endDate is now ajusted inside the submitToProject() method */
			// if (this.newUser.startDate !== '' && !/[T]/g.test(this.newUser.startDate)) {
			// 	this.newUser.startDate += 'T00:00:00Z';
			// }
			// if (this.newUser.endDate !== '' && !/[T]/g.test(this.newUser.endDate)) {
			// 	this.newUser.endDate += 'T23:59:00Z';
			// }

			this.newUser.nightTimeStart = this.updateTime(this.newUser.nightTimeStart);
			this.newUser.nightTimeEnd = this.updateTime(this.newUser.nightTimeEnd);
			this.newUser.minTimeBetweenDays = this.updateTime(this.newUser.minTimeBetweenDays);

			await this.submitToProject(e);
			this.loadingAddProject = false;
		},
		submitToProject: async function (e) {
			e.preventDefault();
			// this.watingAddProject = true
			this.$emit('user-form:watingAddUpdProject', true);

			this.newUser.monthlyRate = parseFloat(this.newUser.monthlyRate);

			// Format start and end date
			const startingDate = moment(this.newUser.startTime).local(true).startOf('day').format('YYYY-MM-DDTHH:mm:ss') + 'Z';
			const startingDatetime = moment(this.newUser.startTime).local(true).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
			const endingDate = moment(this.newUser.endDate).local(true).endOf('day').format('YYYY-MM-DDTHH:mm:ss') + 'Z';

			let NewUser = {
				...this.newUser,
				startDate: startingDate,
				startTime: startingDatetime,
				endDate: endingDate
			};
			// console.log({newUser: this.newUser})
			if (NewUser.isAdmin == 0) {
				NewUser.isAdmin = false;
			} else {
				NewUser.isAdmin = true;
			}

			if (NewUser.isProd == 0) {
				NewUser.isProd = false;
			} else {
				NewUser.isProd = true;
			}

			if (this.projectId > 0) {
				NewUser.projectId = this.projectId;
			}
			// console.log({NewUser});
			NewUser.daysForeseen = parseInt(this.newUser.daysForeseen, 10);
			NewUser.documentPackageId = parseInt(NewUser.documentPackageId, 10);

			try {
				const returnText = await addUserRequest(NewUser);
				const encode = await addEncodingValidation(
					parseInt(store.projectID(), 10),
					0,
					this.userEntryLevelOnForm.timeSheets,
					this.userEntryLevelOnForm.department,
					this.userEntryLevelOnForm.expenses,
					this.userEntryLevelOnForm.po,
					returnText
				);
				if (encode || isNil(encode)) {
					const successText = this.FormMSG(600, 'an email was sent to: ') + this.newUser.email;
					this.createToastForMobile(this.FormMSG(67, 'Success'), successText, '');
					// alert(returnText)
					this.$emit('user-form:success');

					this.isSubmitted = false;
				}
			} catch (error) {
				// this.erreur = error
				// this.errors.push(error)
				// suppose due to email restriction
				this.createToastForMobile(
					this.FormMSG(74, 'Warning'),
					this.FormMSG(73, 'Cannot use this email when you are in DEV or TEST environment.'),
					'',
					'warning'
				);
				this.isSubmitted = false;
			} finally {
				this.$emit('user-form:watingAddUpdProject', false);
			}
		},
		updateTime(value) {
			let now = moment();
			const sValue = value.split(':');
			now.utcOffset(0);
			now.set({
				hour: parseInt(sValue[0], 10),
				minute: parseInt(sValue[1], 10),
				second: 0,
				millisecond: 0
			});
			var retVal = `${moment(now).format('YYYY-MM-DDTHH:mm:ss')}Z`;
			console.log('return value from updateTime:', retVal);
			return retVal;
		},
		isInvalidateFields(payload) {
			this.isInvalid = payload;
		},
		async asyncIsinvalid() {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					resolve(this.isInvalid);
					reject(false);
				}, 200);
			});
		},
		handleCollapsibleHeader(key) {
			this.configAccordion[key].isVisible = !this.configAccordion[key].isVisible;
		},
		getIconLucide(name) {
			return icons[name];
		},
		async handleViewDocumentPackage() {
			await getDocument(this.newUser.documentPackageId).then((records) => {
				records.childDocList = records.childDocList.map((doc) => ({
					...doc,
					src: getFileSrc(doc),
					thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + doc.xid,
					ext: getFileExtension(doc.fileName),
					isHovered: false
				}));

				this.$previewImages({
					images: records.childDocList,
					focusIndex: 0,
					options: {
						presentationMode: 'doc',
						// showStatusFileTag: true,
						hideCommentButton: false,
						hideDisLikeButton: false,
						hideLikeDislike: false,
						hideCropButton: true,
						hideDeleteButton: true,
						showSignFileButton: true
					}
				});
			});
		},
		handleResendDocumentPackage() {
			console.log('Resend Document Package');
		},
		updateStartDate: function ($event) {
			if (this.newUser.startDate.startsWith('T')) {
				this.newUser.startDate = $event + this.newUser.startDate;
			} else {
				this.newUser.startDate = $event + this.newUser.startDate.substring(10);
			}
		},
		updateEndDate: function ($event) {
			if (this.newUser.endDate.startsWith('T')) {
				this.newUser.endDate = $event + this.newUser.endDate;
			} else {
				this.newUser.endDate = $event + this.newUser.endDate.substring(10);
			}
		},
		clearFieldsError() {
			this.isSubmitted = false;
		},
		async getDocumentTemplates() {
			if (this.documentPackageOptions.length === 1) this.documentPackageOptions[0].name = this.FormMSG(69, 'Please, select a document package');
			await getDocumentList({
				documentType: 11
			}).then((records) => {
				records.forEach((record) => {
					if (record.archived >= 2) return;
					record = {
						...record,
						id: parseInt(record.id, 10)
					};
					this.documentPackageOptions.push(record);
				});
			});
		},
		async initCostCenter(departmentId) {
			// set right name of costCenterPres, costCenterShooting, costCenterWrap
			await getBudgetHierarchicalCategoryJson(-2, true, 1).then((result) => {
				let costCenterPrepsOption = [];
				let costCenterShootingOption = [];
				let costCenterWrapOption = [];

				const removeNoneChildrenTree = removeAttributeTree(result, 'children', null);
				this.currentValidCategoryTree = removeNoneChildrenTree;

				costCenterPrepsOption = removeNoneChildrenTree;
				costCenterShootingOption = removeNoneChildrenTree;
				costCenterWrapOption = removeNoneChildrenTree;

				this.costCenterPrepsOptions = [
					{
						id: 0,
						label: this.FormMSG(70, this.msgPrep)
					},
					...costCenterPrepsOption
				];
				this.costCenterShootingOptions = [
					{
						id: 0,
						label: this.FormMSG(71, this.msgShooting)
					},
					...costCenterShootingOption
				];
				this.costCenterWrapOptions = [
					{
						id: 0,
						label: this.FormMSG(72, this.msgWrap)
					},
					...costCenterWrapOption
				];
			});
		},
		initCostMsg() {
			this.costCenterPrepsOptions.push({
				id: 0,
				label: this.FormMSG(70, this.msgPrep)
			});

			this.costCenterShootingOptions.push({
				id: 0,
				label: this.FormMSG(71, this.msgShooting)
			});

			this.costCenterWrapOptions.push({
				id: 0,
				label: this.FormMSG(72, this.msgWrap)
			});
		},
		initializedPayCode(record) {
			record = {
				...record,
				baseSalary1PayCode: record.baseSalary1PayCode === 0 ? null : record.baseSalary1PayCode,
				baseSalary2PayCode: record.baseSalary2PayCode === 0 ? null : record.baseSalary2PayCode,
				baseSalary3PayCode: record.baseSalary3PayCode === 0 ? null : record.baseSalary3PayCode,
				baseSalary4PayCode: record.baseSalary4PayCode === 0 ? null : record.baseSalary4PayCode,
				dayOvt1PayCode: record.dayOvt1PayCode === 0 ? null : record.dayOvt1PayCode,
				dayOvt2PayCode: record.dayOvt2PayCode === 0 ? null : record.dayOvt2PayCode,
				dayOvt3PayCode: record.dayOvt3PayCode === 0 ? null : record.dayOvt3PayCode,
				weekOvt1PayCode: record.weekOvt1PayCode === 0 ? null : record.weekOvt1PayCode,
				weekOvt2PayCode: record.weekOvt2PayCode === 0 ? null : record.weekOvt2PayCode,
				weekOvt3PayCode: record.weekOvt3PayCode === 0 ? null : record.weekOvt3PayCode,
				nightTimePayCode: record.nightTimePayCode === 0 ? null : record.nightTimePayCode,
				restPayCode: record.restPayCode === 0 ? null : record.restPayCode,
				transportPayCode: record.transportPayCode === 0 ? null : record.transportPayCode,
				sundayPayCode: record.sundayPayCode === 0 ? null : record.sundayPayCode,
				seventhDayPayCode: record.seventhDayPayCode === 0 ? null : record.seventhDayPayCode,
				sixthDayPayCode: record.sixthDayPayCode === 0 ? null : record.sixthDayPayCode,
				beforeTimePayCode: record.beforeTimePayCode === 0 ? null : record.beforeTimePayCode,
				travelAllowancePayCode: record.travelAllowancePayCode === 0 ? null : record.travelAllowancePayCode,
				carAllowancePayCode: record.carAllowancePayCode === 0 ? null : record.carAllowancePayCode,
				phoneAllowancePayCode: record.phoneAllowancePayCode === 0 ? null : record.phoneAllowancePayCode,
				computerAllowancePayCode: record.computerAllowancePayCode === 0 ? null : record.computerAllowancePayCode,
				boxKitAllowancePayCode: record.boxKitAllowancePayCode === 0 ? null : record.boxKitAllowancePayCode,
				productionFeeAllowancePayCode: record.productionFeeAllowancePayCode === 0 ? null : record.productionFeeAllowancePayCode,
				lunchPerDiemPayCode: record.lunchPerDiemPayCode === 0 ? null : record.lunchPerDiemPayCode,
				dinerPerDiemPayCode: record.dinerPerDiemPayCode === 0 ? null : record.dinerPerDiemPayCode,
				hotelPerDiemPayCode: record.hotelPerDiemPayCode === 0 ? null : record.hotelPerDiemPayCode,
				abroadPerDiemPayCode: record.abroadPerDiemPayCode === 0 ? null : record.abroadPerDiemPayCode,
				publicHolidayCalendar: record.publicHolidayCalendar === 0 ? null : record.publicHolidayCalendar
			};

			return record;
		},
		initializedPayCodeByRef(parentRefName, record) {
			for (const key of payCodeKeys) {
				const el = this.$refs[parentRefName].$refs[this.generateTextId(key)];
				if (el) {
					el.payrollCodeSelected = record[key];
				}
			}
		},
		generateTextId(id) {
			return generateSecureId(id);
		},
		handleCcn2642(payload) {
			this.isSubmitted = payload;
		},
		scrollUp() {
			const el = this.$refs.scrollTop;
			this.$simpleScrollTo(el, { offset: 100 });
		}
	},
	validations() {
		let result = {
			newUser: {
				userName: {
					required,
					min: minLength(2)
				},
				userFirstName: {
					required,
					min: minLength(2)
				},
				email: {
					required,
					emailValid
				},
				startTime: {
					required,
					dateTimeFrValid
				},
				endDate: {
					required,
					dateFrValid
				},
				department: {
					required,
					min: greaterThanZero
				},
				function: {
					required,
					min: greaterThanZero
				}
			}
		};

		if ([0, 1, 3, 4].includes(this.analyticPeriodType)) {
			result.newUser = _.assign(result.newUser, {
				costCenterPrepsType: {
					required,
					decimal,
					min: greaterThanZero
				}
			});
		}

		if ([1, 2, 4].includes(this.analyticPeriodType)) {
			result.newUser = _.assign(result.newUser, {
				costCenterShootingType: {
					required,
					decimal,
					min: greaterThanZero
				}
			});
		}

		if ([2, 3, 4].includes(this.analyticPeriodType)) {
			result.newUser = _.assign(result.newUser, {
				costCenterWrapType: {
					required,
					decimal,
					min: greaterThanZero
				}
			});
		}

		return result;
	}
};
</script>
